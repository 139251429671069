.container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.text-content {
  flex: 1;
}

.distillery {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  overflow: hidden;
}

.distillery-image {
  margin-left: 20px;
  height:auto;
  max-width: 25vw;
  width: auto;
}

.distillery h2 {
  margin-top: 0;
  color: #333333;
}

.distillery p {
  margin-bottom: 5px;
  color: #777777;
}

.distillery a {
  color: #007bff;
  text-decoration: none;
}

.distillery a:hover {
  text-decoration: underline;
}

